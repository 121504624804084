import React, { useContext, useMemo } from 'react';
import { IconButton, Text, useTheme } from 'react-native-paper';
import { NavigationWithProps } from '../../../core/Types';
import { TherapistNavParamList } from '../Therapist';
import { Image, ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native';
import B from '../../../components/Bold';
import { Gender } from '../../../core/models/enums';
import TherapistAppointmentCard from '../../../components/TherapistAppointmentCard';
import { deserializeAppointment } from '../../../utils/Serialize';
import { AppStoreContext } from '../../../core/provider/AppStoreProvider';


const TherapistConfirmation: React.FC<NavigationWithProps<{}, TherapistNavParamList, "TherapistConfirmation">> = ({ navigation, route }) => {

    const theme = useTheme();

    const therapist = route.params.therapist;
    const appointment = useMemo(() => deserializeAppointment(route.params.appointment), [route.params.appointment]);

    const { myProfile } = useContext(AppStoreContext);

    function goBack() {
        navigation.reset({ index: 0, routes: [{ name: "TherapistProfile", params: therapist }] });
        navigation.getParent()?.navigate("Pages");
    }

    return (
        <SafeAreaView
            style={{
                flex: 1,
                position: "relative"
            }}>
            <IconButton style={{
                marginTop: 50,
                marginLeft: 20,
            }}
                onPress={goBack}
                icon="arrow-left" />

            <ImageBackground
                style={{ flex: 1, paddingHorizontal: 20 }}
                source={require("../../../../assets/img/scenes/fondo_marco.png")}>

                <View style={{
                    alignItems: "center"
                }}>
                    <Image
                        source={require("../../../../assets/img/pet/calendario.png")}
                        style={{
                            objectFit: "contain",
                            width: 220,
                            height: 220,
                        }}
                    />
                </View>
                <Text style={{
                    fontSize: 35,
                    textAlign: "center"
                }}>
                    <B>Cita solicitada!</B>
                </Text>
                <Text style={{
                    marginTop: 20,
                    fontSize: 17,
                    textAlign: "center"
                }}>
                    Felicidades {myProfile?.value?.shortName}. Tu cita con <B>{therapist.full_name}</B> ha sido solicitada.
                    Solo queda esperar a que {therapist.gender == Gender.Women ? "la" : "el"} psicólogo confirme la cita.
                </Text>

                <Text style={{
                    marginTop: 40,
                    fontSize: 20
                }}><B>Mi cita</B></Text>
                <TherapistAppointmentCard
                    therapist={therapist}
                    appointment={appointment} />

            </ImageBackground>
        </SafeAreaView>
    );
}

const style = StyleSheet.create({
    card: {
        paddingHorizontal: 20,
        marginHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 30,
        marginTop: 20
    }
});

export default TherapistConfirmation;
