import { useContext } from "react";
import { SessionContext } from "../provider/SessionProvider";
import { ApiResponse, AuthResponse } from "../models/ApiResponse";
import { BASE_API } from "../hooks/http";
import { AuthorizationI } from "../../../apple-sign-in";
import { useIndexedDB } from "../hooks/indexeddb";

export interface AppleAuthenticateRequest extends AuthorizationI {
    full_name: string;
}

export const useAuth = () => {
    const indexedDB = useIndexedDB();
    const session = useContext(SessionContext);

    if (!session.updateSession) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    const _revokeRefreshToken = async (): Promise<any> => {
        return fetch(`${BASE_API}/userapi/auth/revoke`, { method: "POST" });
    };

    const googleLogin = async (credential: string, accept_terms?: boolean): Promise<void> => {
        const url = `${BASE_API}/userapi/auth/google`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ credential, accept_terms })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                if (!data.ok) {
                    throw data;
                }

                indexedDB.clearChatMessages();
                session?.updateSession?.({ accessToken: data.data!.access_token, refreshToken: data.data!.refresh_token! });
            });
    };

    const appleLogin = async (authorization: AppleAuthenticateRequest, accept_terms?: boolean): Promise<void> => {

        const url = `${BASE_API}/userapi/auth/apple`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...authorization, accept_terms })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                if (!data.ok) {
                    throw data;
                }

                indexedDB.clearChatMessages();
                session?.updateSession?.({ accessToken: data.data!.access_token, refreshToken: data.data!.refresh_token! });
            });
    };

    const refreshToken = async (): Promise<void> => {
        const access_token = session?.session?.current?.accessToken;
        const refresh_token = session?.session?.current?.refreshToken;

        if (!access_token || !refresh_token) {
            throw new Error("Cannot refresh token without a refresh token");
        }

        const url = `${BASE_API}/userapi/auth/refresh`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ access_token, refresh_token })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                if (!data.ok) {
                    throw data;
                }

                session?.updateSession?.({ accessToken: data.data!.access_token, refreshToken: session?.session?.current?.refreshToken ?? "" });
            });
    };

    const logout = async (revoke: boolean = true): Promise<void> => {
        if (revoke) {
            await _revokeRefreshToken()
                .catch((error) => {
                    console.error('Error signing out', error);
                });
        }

        indexedDB.clearChatMessages();
        session?.clearSession?.();
        location.replace('/');
    };

    return {
        googleLogin,
        appleLogin,
        refreshToken,
        logout
    };
};
