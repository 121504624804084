import { Image, View } from "react-native";
import { Icon, Text, useTheme } from "react-native-paper";
import { TherapistModel } from "../core/models/TherapistModel";
import { getProfileImageUrl } from "../utils/Procedures";
import { usePublicApi } from "../core/api/publicApi";
import B from "./Bold";

type Props = {
    therapist: TherapistModel;
    onCardPress?: (appointment: TherapistModel) => void;
}

const TherapistCard: React.FC<Props> = ({ therapist, onCardPress }) => {

    const theme = useTheme();
    const publicApi = usePublicApi();

    return (<View style={{
        borderRadius: 30,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 20,
        backgroundColor: theme.colors.surfaceVariant,
        marginBottom: 20
    }}
        onPointerUp={() => onCardPress?.(therapist)}>

        {therapist.is_premium && <View style={{
            position: "absolute",
            top: 20,
            right: 20,
        }}>

            <Icon source="check-decagram"
                size={20}
                color={theme.colors.primary} />
        </View>}

        <Image
            style={{
                height: 90,
                width: 90,
                borderRadius: 50
            }}
            source={getProfileImageUrl(therapist, publicApi)} />
        <View style={{
            flexBasis: "60%"
        }}>
            <Text style={{ fontSize: 20, color: theme.colors.onSurfaceVariant }}><B>{therapist.full_name}</B></Text>
            <Text style={{ fontSize: 17, color: theme.colors.onSurfaceVariant }}>{therapist.speciality}</Text>
            <Text style={{ fontSize: 14, color: theme.colors.onSurfaceVariant }}>
                {therapist.description.slice(0, 50) + '...'}
            </Text>
        </View>
    </View>);
};

export default TherapistCard;
