import { MD3LightTheme, MD3Theme, adaptNavigationTheme, configureFonts } from "react-native-paper";
import { MD3Type, NavigationTheme } from "react-native-paper/lib/typescript/types";
import {
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import '../styles/fonts.css';

const navigationTheme = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
});

const fontConfig: Partial<MD3Type> = {
    fontFamily: "Nunito-Regular",
};


const LightTheme: MD3Theme & NavigationTheme = {
    ...MD3LightTheme,
    ...navigationTheme.LightTheme,

    colors: {
        ...MD3LightTheme.colors,
        ...navigationTheme.LightTheme.colors,
        "primary": "#4e662a",
        "onPrimary": "#ffffff",
        "primaryContainer": "#cfeda2",
        "onPrimaryContainer": "#111f00",
        "secondary": "#586249",
        "onSecondary": "#ffffff",
        "secondaryContainer": "#dce7c7",
        "onSecondaryContainer": "#161e0a",
        "tertiary": "#386662",
        "onTertiary": "#ffffff",
        "tertiaryContainer": "#bcece6",
        "onTertiaryContainer": "#00201e",
        "error": "#ba1a1a",
        "onError": "#ffffff",
        "errorContainer": "#ffdad6",
        "onErrorContainer": "#410002",
        "background": "#ffffff",
        "onBackground": "#1a1c16",
        "surface": "#ffffff",
        "onSurface": "#1a1c16",
        "surfaceVariant": "#e8e9de",
        "onSurfaceVariant": "#45483d",
        "outline": "#75796c",
        "outlineVariant": "#c5c8b9",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "#2f312a",
        "inverseOnSurface": "#f1f2e6",
        "inversePrimary": "#b3d088",
        "elevation": {
            "level0": "transparent",
            "level1": "rgb(243, 245, 233)",
            "level2": "rgb(237, 240, 225)",
            "level3": "rgb(231, 236, 218)",
            "level4": "rgb(230, 235, 216)",
            "level5": "rgb(226, 232, 211)"
        },
        "surfaceDisabled": "rgba(27, 28, 24, 0.12)",
        "onSurfaceDisabled": "rgba(27, 28, 24, 0.38)",
        "backdrop": "rgba(46, 50, 40, 0.4)"
    },

    fonts: configureFonts({ config: fontConfig, isV3: true }),
    dark: false,
};

export default LightTheme
