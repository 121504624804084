import { Linking, View } from "react-native";
import { AppointmentModel, IAppointment } from "../core/models/AppointmentModel";
import { Badge, Button, Text, useTheme } from "react-native-paper";
import { AppointmentStatus } from "../core/models/enums";
import { formatDateTime, formatShortWeekDay } from "../utils/Formatting";
import B from "./Bold";
import { useMemo } from "react";
import { getAppointmentStatus } from "../utils/Procedures";
import { useAppointments } from "../core/api/appointments";

type Props = {
    appointment: AppointmentModel;
    onCancelPress?: (appointment: IAppointment) => void;
    onPress?: (appointment: IAppointment) => void;
}

const AppointmentCard: React.FC<Props> = ({ appointment, onCancelPress, onPress }) => {

    const theme = useTheme();

    const appointments = useAppointments();

    const statusToColor = (status: AppointmentStatus) => {
        switch (status) {
            case AppointmentStatus.PendingConfirmation:
            case AppointmentStatus.PendingPayment:
                return theme.colors.secondary;

            case AppointmentStatus.CancelledByTherapist:
            case AppointmentStatus.CancelledByUser:
                return theme.colors.tertiary;

            case AppointmentStatus.Confirmed:
            default:
                return theme.colors.primary;
        }
    };

    const openMeetingUrl = async () => {
        const url = await appointments.getAppointmentMeetingUrl(appointment.therapist_id, appointment.id);
        Linking.openURL(url);
    }

    const canBeCacelled = useMemo(() => {
        return !appointment.isInProgress && !appointment.isCompleted;
    }, [appointment.status]);

    return (
        <View style={{
            borderRadius: 30,
            padding: 20,
            backgroundColor: theme.colors.surfaceVariant,
            marginBottom: 20
        }}>

            <Text
                style={{ fontSize: 24, color: theme.colors.onSurfaceVariant }}
                onPress={() => onPress?.(appointment)}>
                <B>{appointment?.therapist?.full_name}</B>
            </Text>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Text style={{ fontSize: 14, color: theme.colors.onSurfaceVariant }}>
                    {formatShortWeekDay(appointment.time_slot)} - {formatDateTime(appointment.time_slot)}
                </Text>
                <Badge style={{
                    alignSelf: 'flex-start',
                    backgroundColor: statusToColor(appointment.status),
                    paddingHorizontal: 10,
                    marginBottom: 5
                }}>
                    {getAppointmentStatus(appointment)}
                </Badge>
            </View>
            {
                canBeCacelled && <Button
                    mode="text"
                    style={{
                        marginRight: 'auto'
                    }}
                    labelStyle={{
                        marginLeft: 0,
                        marginVertical: 0,
                        color: theme.colors.error
                    }}
                    contentStyle={{
                        flexDirection: 'row-reverse'
                    }}
                    onPress={() => onCancelPress?.(appointment)}>
                    <Text style={{ color: theme.colors.error }}>Cancelar</Text>
                </Button>
            }
            {
                appointment.isInProgress && <Button
                    mode="text"
                    style={{
                        marginRight: 'auto'
                    }}
                    labelStyle={{
                        marginLeft: 0,
                        marginVertical: 0,
                        color: theme.colors.primary
                    }}
                    contentStyle={{
                        flexDirection: 'row-reverse',
                    }}
                    icon='arrow-right'
                    onPress={() => { openMeetingUrl() }}>
                    <Text style={{ color: theme.colors.primary }}>Ir a la cita</Text>
                </Button>
            }
        </View >
    );
};

export default AppointmentCard;
