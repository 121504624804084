import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createMaterialBottomTabNavigator } from 'react-native-paper/react-navigation';
import { Icon, useTheme } from 'react-native-paper';
import Appointments from './Appointments/Appointments';
import Chat from './Chat/Chat';
import Home from './Home/Home';
import { AppStoreContext } from '../../core/provider/AppStoreProvider';
import { RootNavParamList } from '../../../App';
import { NavigationWithProps } from '../../core/Types';

export type PagesNavParamList = {
    "Home": undefined,
    "Chat": undefined,
    "SearchTherapist": undefined,
    "Appointments": undefined
};

const Tab = createMaterialBottomTabNavigator<PagesNavParamList>();
const Pages: React.FC<NavigationWithProps<{}, RootNavParamList, "Pages">> = ({ navigation }) => {

    const [showNavigationBar, setShowNavigationBar] = useState(true);

    const theme = useTheme();

    const appContext = useContext(AppStoreContext);

    useEffect(() => {
        // If the user has not selected a therapist, redirect to the therapist selection page
        // We are listening to the therapist value, if it changes, we will redirect to the therapist selection page
        if (appContext.myTherapist?.value == undefined) {
            navigation.navigate("SelectTherapist");
        }
    }, [appContext]);

    return (<>
        <Tab.Navigator id='PageNavigator'
            initialRouteName="Home"
            barStyle={{
                backgroundColor: theme.colors.surface,
                borderTopColor: theme.colors.surfaceVariant,
                borderTopWidth: 1,
                display: showNavigationBar ? 'flex' : 'none'
            }}
        >
            <Tab.Screen
                name="Home"
                component={Home}
                options={{
                    tabBarLabel: 'Inicio',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "account-heart" : "account-heart-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(true),
                }}
            />

            <Tab.Screen
                name="Chat"
                component={Chat}
                options={{
                    tabBarLabel: 'Chat',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "message" : "message-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(false),
                }}
            />

            <Tab.Screen
                name="Appointments"
                component={Appointments}
                options={{
                    tabBarLabel: 'Citas',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "calendar-blank" : "calendar-blank-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(true),
                }}
            />
        </Tab.Navigator>
    </>);
}

export default Pages;
