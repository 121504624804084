import { ReactNode, createContext, useEffect, useRef, useState } from "react";

export interface Session {
    accessToken: string;
    refreshToken: string;
}

type SessionContextProps = {
    session?: React.MutableRefObject<Session | undefined>;
    updateSession?: (session?: Session) => void;
    clearSession?: () => void;
}

export const SessionContext = createContext<SessionContextProps>({});

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [initializing, setInitializing] = useState<boolean>(true);
    const _session = useRef<Session | undefined>(undefined);

    const attemptSessionRestore = () => {
        const accessToken = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (!accessToken || !refreshToken) {
            return;
        }

        _session.current = { accessToken, refreshToken };
    };

    const setSessionAndStore = (session?: Session) => {
        if (!session) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
        } else {
            localStorage.setItem("access_token", session.accessToken);
            localStorage.setItem("refresh_token", session.refreshToken);
        }

        _session.current = session;
    };

    const clearSession = () => {
        setSessionAndStore(undefined);
    };

    useEffect(() => {
        attemptSessionRestore();
        setInitializing(false);
    }, []);

    return (
        <SessionContext.Provider value={{ session: _session, updateSession: setSessionAndStore, clearSession }}>
            {!initializing && children}
        </SessionContext.Provider>
    );
}
