export default class AsyncLock {
    private _locked = false;
    private _lock_promise: Promise<void> = Promise.resolve();
    private _lock_resolve: (() => void) | null = null;

    public isLocked(): boolean {
        return this._locked;
    }

    public async lock() {
        if (this._locked) {
            await this._lock_promise;
        }

        this._locked = true;
        this._lock_promise = new Promise<void>((resolve) => {
            this._lock_resolve = resolve;
        });
    }

    public unlock() {
        if (this._locked) {
            this._locked = false;
            this._lock_resolve!();
            this._lock_resolve = null;
        }
    }
}
