import React, { useState } from 'react';
import { SafeAreaView, StatusBar, View, Image, ImageBackground, ScrollView } from 'react-native';
import { Searchbar, Text, useTheme } from 'react-native-paper';
import { NavigationWithProps } from '../../core/Types';
import { RootNavParamList } from '../../../App';
import { useTherapists } from '../../core/api/therapists';
import { TherapistModel } from '../../core/models/TherapistModel';
import { Background, Pet } from '../../assets/images';
import { CardListLoader } from '../../components/Loaders';
import TherapistCard from '../../components/TherapistCard';
import { PaginatedResponse } from '../../core/models/ApiResponse';

const SelectTherapist: React.FC<NavigationWithProps<{}, RootNavParamList, "SelectTherapist">> = ({ navigation }) => {

    const theme = useTheme();
    const therapists = useTherapists();

    const [therapistList, setTherapistList] = useState<PaginatedResponse<TherapistModel> | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const fetchTherapists = async (searchText?: string) => {
        setLoading(true);
        setSubmitted(false);
        setTherapistList(undefined);

        const search = searchText?.trim() ?? "";
        if (search.length === 0) {
            setLoading(false);
            return;
        }

        const data = await therapists.listTherapists(search);
        setTherapistList(data);

        setSubmitted(true);
        setLoading(false);
    };

    const openTherapistProfile = (therapist: TherapistModel) => {
        navigation.navigate("Therapist", therapist);
    };

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: theme.colors.surface,
            position: "relative",
        }}>
            <StatusBar
                translucent
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
            />
            <ImageBackground
                style={{ flex: 1, paddingHorizontal: 20 }}
                source={Background.fullscreen}>

                <View style={{
                    alignItems: "center",
                    marginTop: 70,
                    marginBottom: 20
                }}>
                    <Image
                        source={Pet.greetingsFront}
                        style={{
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                        }}
                    />
                </View>

                <Searchbar
                    style={{ marginBottom: 20, marginHorizontal: 20 }}
                    placeholder="Nombre"
                    value={searchText}
                    onChangeText={setSearchText}
                    onSubmitEditing={() => fetchTherapists(searchText)}
                    onClearIconPress={() => fetchTherapists()} />

                <ScrollView style={{
                    flex: 1,
                    marginBottom: 120,
                    paddingHorizontal: 20
                }}>
                    {
                        loading ?
                            <CardListLoader />
                            : therapistList && therapistList?.items.length > 0 ?
                                therapistList.items.map(therapist =>
                                    <TherapistCard
                                        key={therapist.id}
                                        therapist={therapist}
                                        onCardPress={openTherapistProfile} />
                                )
                                : submitted ?
                                    <Text style={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        color: theme.colors.onSurfaceVariant,
                                        marginTop: 20,
                                        marginBottom: 100
                                    }}>
                                        No se encontró ningún psicólogo con ese nombre.
                                    </Text>
                                    : <Text style={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        color: theme.colors.onSurfaceVariant,
                                        marginTop: 20,
                                        marginBottom: 100
                                    }}>
                                        Introduce el nombre del psicologo que le ha invitado a Kyra health para continuar.
                                    </Text>
                    }
                </ScrollView>
            </ImageBackground>
        </SafeAreaView >
    );
}

export default SelectTherapist;
