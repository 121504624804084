import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from './src/app/login/Login';
import Pages from './src/app/pages/Pages';
import { useTheme } from 'react-native-paper';
import { NavigationTheme } from 'react-native-paper/lib/typescript/types';
import { TherapistModel } from './src/core/models/TherapistModel';
import Therapist from './src/app/Therapist/Therapist';
import { SessionContext } from './src/core/provider/SessionProvider';
import { useTherapists } from './src/core/api/therapists';
import Onboarding from './src/app/onboarding/Onboarding';
import SelectTherapist from './src/app/Therapist/SelectTherapist';
import { AppStoreContext } from './src/core/provider/AppStoreProvider';
import { useMe } from './src/core/api/me';
import LoadingScreen from './src/components/LoadingScreen';

export type RootNavParamList = {
    "Login": undefined,
    "Onboarding": undefined,
    "Pages": undefined,
    "SelectTherapist": undefined,
    "Therapist": TherapistModel
};

const Stack = createNativeStackNavigator<RootNavParamList>();

const App: React.FC = () => {

    const session = useContext(SessionContext);
    const appContext = useContext(AppStoreContext);

    const [ready, setReady] = useState(false);
    const [hasOnboarding, setHasOnboarding] = React.useState<boolean>();
    const [requestedTherapist, setRequestedTherapist] = useState<TherapistModel>();

    const hasRequestedTherapist = useMemo(() => {
        return requestedTherapist !== undefined;
    }, [requestedTherapist]);
    const hasSession = useMemo(() => {
        return session.session?.current !== undefined;
    }, [session]);
    const hasTherapist = useMemo(() => {
        return appContext.myTherapist?.value !== undefined;
    }, [appContext]);

    const theme = useTheme();
    const therapists = useTherapists();
    const me = useMe();
    // const onboarding = useOnboarding();

    const checkOnboarding = async () => {
        setHasOnboarding(true);
        return true;

        //TODO: Uncomment this when the onboarding is ready
        // if (!hasSession) {
        //     setHasOnboarding(false);
        //     return false;
        // }

        // const ok = await onboarding.isFilled()
        //     .catch((error) => {
        //         //TODO: Handle error
        //         console.error('Error:', error);
        //         return false;
        //     });
        // setHasOnboarding(ok);

        // return ok;
    };

    const checkTherapistInPath = async () => {
        if (location.pathname !== "/therapist") {
            return;
        }
        const params = new URLSearchParams(location.search);
        const therapistId = Number(params.get('id'));
        if (!therapistId || isNaN(therapistId)) {
            return;
        }

        const therapist = await therapists.getTherapist(therapistId)
            .catch(() => undefined);
        if (!therapist) {
            return;
        }
        setRequestedTherapist(therapist);
    };

    const init = async () => {
        if (!hasSession) {
            setReady(true);
            return;
        }

        const ok = await checkOnboarding();
        if (ok) {
            await Promise.all([
                checkTherapistInPath(),
                me.fetchMyProfile(),
                therapists.fetchMyTherapist(),
            ]);
            history.replaceState({}, '', '/');
        }
        setReady(true);
    };

    useEffect(() => {
        init();
    }, []);

    return (<>
        {ready ?
            <NavigationContainer
                documentTitle={{
                    //TODO: We should put this in a constants file
                    formatter: (options, route) =>
                        "Kyra Health",
                }}
                theme={theme as unknown as NavigationTheme}>
                <Stack.Navigator id='AppNavigator'
                    screenOptions={
                        { headerShown: false }
                    }
                    initialRouteName={hasSession ?
                        (hasOnboarding ?
                            (hasRequestedTherapist ? 'Therapist' :
                                (hasTherapist ? 'Pages' : 'SelectTherapist'))
                            : 'Onboarding')
                        : 'Login'}>
                    <Stack.Screen
                        name="Login"
                        component={Login}
                    />

                    <Stack.Screen
                        name="Onboarding"
                        component={Onboarding}
                    />

                    <Stack.Screen
                        name="Pages"
                        component={Pages}
                    />

                    <Stack.Screen
                        name="SelectTherapist"
                        component={SelectTherapist}
                    />

                    <Stack.Screen
                        name="Therapist"
                        component={Therapist}
                        initialParams={requestedTherapist}
                    />
                </Stack.Navigator>
            </NavigationContainer> :
            <LoadingScreen />
        }
    </>);
}

export default App;
