import { Language, Concern } from "./enums";

export enum ApiErrors {
    ACCEPT_TERMS_ERROR = "ACCEPT_TERMS",
    QUOTA_EXHAUSTED_ERROR = "QUOTA_EXHAUSTED",
    NEEDS_SUBSCRIPTION_ERROR = "NEED_SUBSCRIPTION"
}

export interface ApiResponse<T = void> {
    ok: boolean;
    data?: T;
    error?: string;
}

export interface IPaginatedResponse<T> {
    items: T[];
    total: number;
}

export class PaginatedResponse<T> implements IPaginatedResponse<T> {
    items: T[];
    total: number;
    currentPage: number;
    pageSize: number;

    get numPages() {
        return Math.ceil(this.total / this.pageSize);
    }

    get hasNextPage() {
        return this.numPages > this.currentPage;
    }

    get hasPreviousPage() {
        return this.currentPage > 1;
    }

    constructor(paginatedResponse: IPaginatedResponse<T>, currentPage: number, pageSize: number) {
        this.items = paginatedResponse.items;
        this.total = paginatedResponse.total;
        this.pageSize = pageSize;
        this.currentPage = currentPage;
    }
}

export interface AuthResponse {
    access_token: string;
    refresh_token?: string;
}

export interface OnboardingRequest {
    language: Language;
    concerns: Concern[];
}
