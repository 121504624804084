import { View } from "react-native";
import LoadingIcon from "./LoadingIcon";

const LoadingScreen: React.FC = () => (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <LoadingIcon active={true} />
    </View>
);

export default LoadingScreen;
