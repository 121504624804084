import React, { useContext, useEffect, useMemo } from 'react';
import { ImageBackground, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import B from '../../../components/Bold';
import { NavigationWithProps } from '../../../core/Types';
import { PagesNavParamList } from '../Pages';
import { RootNavParamList } from '../../../../App';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { TherapistModel } from '../../../core/models/TherapistModel';
import TherapistAppointmentCard from '../../../components/TherapistAppointmentCard';
import { AppStoreContext } from '../../../core/provider/AppStoreProvider';
import { Background, Scenes } from '../../../assets/images';


// const motivationalQuotes = [
//     '"La mente es como un paracaídas: sólo funciona si se abre." (James Dewar) - Invita a ser receptivos a nuevas ideas y perspectivas.',
//     '"No es lo que te pasa lo que importa, sino cómo reaccionas ante ello." (Epicteto) - Enfatiza la importancia de nuestra actitud frente a los desafíos.',
//     '"La felicidad no es algo hecho. Viene de tus propias acciones." (Dalai Lama) - Subraya que nuestra felicidad está en nuestras manos.',
//     '"El miedo a fracasar es mucho peor que el fracaso en sí." (Pablo Picasso) - Anima a salir de la zona de confort y tomar riesgos.',
//     '"Lo que pensamos crea nuestra realidad." (Buddha) - La importancia de los pensamientos positivos en la creación de nuestra experiencia.'
// ];

const Home: React.FC<NavigationWithProps<{}, PagesNavParamList, "Home", "AppNavigator">> = ({ navigation, route }) => {

    const theme = useTheme();

    const appContext = useContext(AppStoreContext);

    // const randomQuote = useMemo(() => {
    //     const index = Math.floor(Math.random() * motivationalQuotes.length);
    //     return motivationalQuotes[index];
    // }, []);

    const welcomeMessage = useMemo(() => {
        const date = new Date();
        const hour = date.getHours();
        if (hour < 12) {
            return "Buenos días";
        } else if (hour < 19) {
            return "Buenas tardes";
        } else {
            return "Buenas noches";
        }
    }, []);

    const openTherapistProfile = (therapist: TherapistModel) => {
        navigation
            .getParent<NativeStackNavigationProp<RootNavParamList>>("AppNavigator")
            ?.navigate("Therapist", therapist);
    }

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: theme.colors.surfaceVariant,
            position: "relative",
        }}>
            <StatusBar
                translucent
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
            />

            <View>
                <ImageBackground
                    style={{
                        width: "100%",
                        height: 200,
                        gap: 15,
                    }}
                    imageStyle={{ resizeMode: 'cover' }}
                    source={Scenes.meditatingTent}>
                </ImageBackground>

                <View style={{
                    alignItems: "center",
                }}>
                    <Text style={{
                        fontSize: 25,
                        color: theme.colors.onSurfaceVariant,
                        marginTop: 10
                    }}>
                        <B>{welcomeMessage}, {appContext.myProfile?.value?.shortName}</B>
                    </Text>

                    <Button
                        style={{ marginTop: 20 }}
                        onPress={() => navigation.navigate("Chat")}
                        mode='contained'>
                        Habla con Kyra
                    </Button>
                </View>
            </View>

            <ScrollView style={{
                position: 'absolute',
                height: "100%",
                width: "100%",
                pointerEvents: 'none',
            }}>
                <View style={{ height: 320 }} />
                <View style={{
                    borderTopEndRadius: 30,
                    borderTopStartRadius: 30,
                    backgroundColor: theme.colors.surface,
                    pointerEvents: 'auto',
                }}>
                    <View style={{
                        paddingHorizontal: 20,
                        paddingTop: 20,
                        flex: 1
                    }}>
                        <Text style={{ fontSize: 20 }}><B>Mi psicólogo</B></Text>
                        <TherapistAppointmentCard
                            therapist={appContext.myTherapist?.value?.therapist}
                            appointment={appContext.myTherapist?.value?.next_appointment}
                            onShowProfile={(therapist) => openTherapistProfile(therapist)}
                            onTherapistSearch={() => navigation.navigate("SearchTherapist")} />

                        <View style={{
                            minHeight: 10,
                            height: "calc(100vh - 750px)" as any
                        }} />

                        {/* <Text style={{
                            fontSize: 19,
                            marginTop: 10,
                            marginBottom: 60,
                            margin: 10,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: theme.colors.onSurface
                        }}>
                            {randomQuote}
                        </Text> */}

                    </View>
                    <ImageBackground
                        style={{
                            height: 100
                        }}
                        resizeMode='cover'
                        source={Background.small} />
                </View>
            </ScrollView>
        </SafeAreaView >
    );
}

export default Home;
